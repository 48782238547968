<app-loading-spinner [loading]="loading"></app-loading-spinner>
<div class="card-scroll">
  <div class="row">
    <div class="col-lg-12">
      <h1>Términos y Condiciones</h1>
    </div>
  </div>
  <div class="line"></div>
  <div class="row">
    <div
      class="col-lg-12 mt-4"
      style="text-align: justify"
      id="text"
      [innerHTML]="content"
    ></div>
  </div>
</div>
