import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class AllTransactionsService {
  constructor(private http: HttpService) { }

  getCompanies() {
    return this.http.get('/company/companies');
  }

  getAllTransactions(
    limit?: number,
    page?: number,
    sucursal?: string,
    startDate?: string,
    endDate?: string,
    search?: string
  ) {
    let filter = [];
    if (limit) filter.push(`limit=${limit}`);
    if (page) filter.push(`page=${page}`);
    if (sucursal) filter.push(`companyId=${sucursal}`);
    if (startDate) filter.push(`startDate=${startDate}`);
    if (endDate) filter.push(`endDate=${endDate + 'T23:59:59'}`);
    if (search) filter.push(`search=${search}`);
    return this.http.get(`/company/all-transactions?${filter.join('&')}`);
  }
  getAllTransactionsReport(
    sucursal?: string,
    startDate?: string,
    endDate?: string
  ) {
    let filter = [];
    if (sucursal) filter.push(`companyId=${sucursal}`);
    if (startDate) filter.push(`startDate=${startDate}`);
    if (endDate) filter.push(`endDate=${endDate + 'T23:59:59'}`);
    return this.http.get(`/report/all-transactions?${filter.join('&')}`);
  }

  getAllPrepayCollaborators(limit: any, page: any, search?: string, companyId?: string) {
    let filter = [];
    if (limit) filter.push(`limit=${limit}`);
    if (page) filter.push(`page=${page}`);
    if (search) filter.push(`search=${search}`);
    if (companyId) filter.push(`companyId=${companyId}`);
    return this.http.get(`/company/customer-search?${filter.join('&')}`);
  }
}
