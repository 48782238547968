 <form class="w-100 my-3" [formGroup]="passwordForm">

   <div class="row">
     <div class="col-lg-12 mb-4">
       <span class="text-size-35px bold">
        Ingresar
       </span>
     </div>
   </div>

   <div class="col-lg-12 mb-3 between">
     <label for="exampleInputEmail1">Contraseña Nueva</label>
     <input type="password" 
     class="form-control" 
     placeholder="*****" 
     formControlName="newPassword"
     #y
     >
     <button
        class="btn reveal-button"
        type="button"
        id="button-addon"
        (click)="y.type = y.type == 'password' ? 'text' : 'password'"
        style="color:#A5A5A5;"
        >
        <i class="bi bi-eye" style="font-size: 24px"></i>
      </button>
     <label
        for=""
        *ngIf="
        !passwordForm.get('newPassword')?.valid &&
        (passwordForm.get('newPassword')?.touched ||
        passwordForm.get('newPassword')?.dirty)
        "
        class="incorrect float-end mt-2"
        >Contraseña invalida</label
        >
   </div>

   <div class="col-lg-12 mb-4">
     <label>Repetir Contraseña</label>
     <input type="password" 
     class="form-control" 
     placeholder="Ingresa tu clave" 
     formControlName="confirmPassword"
     #z
     >
     <button
        class="btn reveal-button"
        type="button"
        id="button-addon2"
        (click)="z.type = z.type == 'password' ? 'text' : 'password'"
        style="color:#A5A5A5;"
        >
        <i class="bi bi-eye" style="font-size: 24px"></i>
    </button>
     <label
        for=""
        *ngIf="
        passwordForm.get('newPassword')?.value !=
        passwordForm.get('confirmPassword')?.value &&
        passwordForm.get('confirmPassword')?.touched
        "
        class="incorrect float-end mt-2"
        >Las contraseñas no coinciden</label
       >
   </div>

   <div class="col-12 ">
     <app-card class="bg-purple py-4 px-5 bold text-size-13px" style="margin-top:10%">
       Mínimo 8 caracteres, debe contener al menos
       una mayúscula, un número y un símbolo.
     </app-card>
   </div>

   <div class="col-lg-12 d-flex my-4">
     <button type="button" 
     class="btn btn-dark btn-block"
     [disabled]="!passwordForm.valid" 
     (click)="submit()">
       Ingresar
     </button>
   </div>

  <!-- <div class="col-lg-12 text-center">
     <span class="text-size-14px">Al registrarte, aceptas nuestra <a [routerLink]=""
         class="text-primary text-decoration-none">Política de privacidad</a>, <a [routerLink]=""
         class="text-primary text-decoration-none">Política de cookies</a> y los <a [routerLink]=""
         class="text-primary text-decoration-none">Terminos de uso</a></span>
   </div>-->
 </form>
