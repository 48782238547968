<div class="row justify-content-center py-5 px-3">
  <div class="col-lg-8">
    <h3 class="mb-2 text-center">Seleccione el rol a usar</h3>
    <app-card
      class="p-3 mb-3"
      *ngFor="let item of roles"
      style="box-shadow: 0px 0px 4px rgba(104, 130, 247, 0.5); cursor: pointer"
      (click)="selectRole(item._id)"
    >
      <div class="row justify-content-between">
        <div class="col-md-5 d-flex align-items-center">
          {{ item.name }}
        </div>
        <div class="col-md-3 d-flex align-items-center">
          <span class="badge-green regular px-4">Activo</span>
        </div>
      </div>
    </app-card>
  </div>
</div>
