<app-card class="p-4 mb-4">
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <h3 class="mb-4"><i class="bi bi-upload me-3"></i> Subir documento</h3>
    </div>

    <div
      class="
        col-lg-4
        d-flex
        flex-column
        align-items-center align-items-lg-start
        mb-3
      "
    >
      <label>Subir Comprobante bancario</label>
      <div class="mb-2">
        <input
          type="file"
          hidden
          accept=".pdf,.jpg,.png"
          (change)="uploadDocument($event)"
          #hiddenInput
        />

        <button
          type="button"
          class="btn btn-dark px-4"
          (click)="hiddenInput.click()"
        >
          <i class="bi bi-upload me-2"></i> Subir documento
        </button>
      </div>
      <span class="text-size-14px text-light-gray">Formato PDF, PNG, JPG</span>
      <span class="text-size-14px text-danger" *ngIf="uploaded"
        >Documento subido con exito</span
      >
    </div>

    <div
      class="
        col-lg-4
        d-flex
        flex-column
        align-items-center align-items-lg-start
        mb-3
      "
    >
      <!-- <label>Subir documento asociado</label>
      <div class="mb-2">
        <button type="button" class="btn btn-dark px-4">
          <i class="bi bi-upload me-3"></i> Subir documento
        </button>
      </div>
      <span class="text-size-14px text-light-gray">Documento en PDF, Word, Excel</span> -->
    </div>

    <div
      class="
        col-lg-4
        d-flex
        flex-column
        align-items-center align-items-lg-start
        mb-3
      "
    >
      <!-- <label>Subir documento asociado</label>
      <div class="mb-2">
        <button type="button" class="btn btn-dark px-4">
          <i class="bi bi-upload me-3"></i> Subir documento
        </button>
      </div>
      <span class="text-size-14px text-light-gray">Documento en PDF, Word, Excel</span> -->
    </div>
  </div>
</app-card>

<div class="row justify-content-end">
  <div class="col-lg-3 mb-4">
    <button
      type="button"
      class="btn btn-outline-dark btn-block float-lg-end"
      cdkStepperPrevious
    >
      <i class="bi bi-arrow-left"></i>Volver
    </button>
  </div>
  <div class="col-lg-3 mb-4">
    <button
      type="button"
      class="btn btn-dark btn-block float-lg-end"
      cdkStepperNext
      [disabled]="!form.valid"
    >
      Siguiente<i class="bi bi-arrow-right"></i>
    </button>
  </div>
</div>
