import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'app-end-user-agreement',
  templateUrl: './end-user-agreement.component.html',
  styleUrls: ['./end-user-agreement.component.scss'],
})
export class EndUserAgreementComponent implements OnInit {
  form: FormGroup;
  content!: any;
  private ngUnsubscribe = new Subject();
  userId!: any;
  public loading = false;
  constructor(
    private fb: FormBuilder,
    private authSvc: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = this.fb.group({
      isAccepted: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    this.getTerm();
    this.route.paramMap
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((param) => {
        this.userId = param.get('userId') ?? '';
        console.log('user id', this.userId);
      });
  }

  getTerm() {
    this.authSvc.getTermsAndConditions().subscribe((data) => {
      console.log('data ', data);
      this.content = data.term;
    });
  }

  submit() {
    this.loading = true;
    this.authSvc.acceptTermAndConditions(this.userId).subscribe(
      (data) => {
        console.log('response ', data);
        this.router.navigate(['/']);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  cancel() {
    this.authSvc.logout();
    this.router.navigate(['/auth/login']);
  }
}
