import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PasswordRestoredModalComponent } from 'src/app/shared/modals/password-restored-modal/password-restored-modal.component';
import { passwordValidator } from 'src/app/shared/utils/validators/password.validator';
import { OperationFinishedModalComponent } from 'src/app/shared/modals/operation-finished-modal/operation-finished-modal.component';
import { Router,ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/layouts/dash/modules/user-management/services/user.service'

@Component({
  selector: 'app-validate-account',
  templateUrl: './validate-account.component.html',
  styleUrls: ['./validate-account.component.scss'],
})
export class ValidateAccountComponent implements OnInit {

  token = "";
  private ngUnsubscribe = new Subject();

  constructor(
    private modalSvc: BsModalService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService
  ) {}

  ifDifferent: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const newPassword = control.get('newPassword')?.value;
    const confirmNewPassword = control.get('confirmPassword')?.value;
    return newPassword != confirmNewPassword ? { different: true } : null;
  };

  passwordForm: FormGroup = this._formBuilder.group(
    {
      newPassword: [null, [Validators.required, passwordValidator()]],
      confirmPassword: [null, Validators.required],
    },
    { validators: this.ifDifferent }
  )
  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(param => {
        this.token = param.get('token') ?? '';
      })
  }

  showModal() {
    this.modalSvc.show(PasswordRestoredModalComponent, {
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        successful: true,
      },
    }).onHidden?.subscribe(data =>{
      this.router.navigate(['/'])
    });
  }

  submit() {
    console.log('form value ', this.passwordForm.value,'token ',this.token)
    this.userSvc.recoverPasswordStep2(this.token,this.passwordForm.value).subscribe(data =>{
      if(!data.error){
        this.showModal();
      }
    })
    
  }
}
