import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AllCollaboratorsService {

  constructor(private http: HttpService) { }

  getCompanies() {
    return this.http.get('/company/companies')
  }

  getAllCompanies(type?: string) {
    let filter = [];
    if (type) filter.push(`type=${type}`);
    return this.http.get(`/company/all-companies?${filter.join('&')}`)
  }

  getAllCollaborators(limit?: number, page?: number, sucursal?: string, search?: string) {
    let filter = [];
    if (limit) filter.push(`limit=${limit}`);
    if (page) filter.push(`page=${page}`);
    if (sucursal) filter.push(`companyId=${sucursal}`);
    if (search) filter.push(`search=${search}`);

    return this.http.get(`/company/all-consumers?${filter.join('&')}`)
  }


  getAllCollaboratorsReport(sucursal?: string) {
    let filter = [];
    if (sucursal) filter.push(`companyId=${sucursal}`);
    return this.http.get(`/report/all-consumers?${filter.join('&')}`)
  }

  createCollaborator(payload: any) {
    return this.http.post('/company/create-consumer', payload)
  }

  collaboratorsBulk(payload: any) {
    return this.http.post(`/company/bulk-create-consumers`, payload)
  }

  async consumersBulk(payload: any) {
    return await this.http
      .post(`/company/bulk-consumers`, payload)
      .pipe(take(1))
      .toPromise();
  }
   
  deleteCollaborator(id: string) {
    return this.http.delete(`/company/delete-account/${id}`)
  }

}
