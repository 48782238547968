import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUtils } from 'src/app/shared/utils/file.utils';

@Component({
  selector: 'app-attach-documents-form',
  templateUrl: './attach-documents-form.component.html',
  styleUrls: ['./attach-documents-form.component.scss'],
})
export class AttachDocumentsFormComponent implements OnInit {
  form: FormGroup;
  uploaded: boolean = false;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      voucher: [null, Validators.required],
      extension: [null],
    });
  }

  ngOnInit(): void {}

  async uploadDocument(event: any) {
    const name = event.target.files[0].name;
    const extension = name.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2);
    const file = event.target.files[0];
    const base64 = await FileUtils.toBase64(file);
    this.form.patchValue({
      extension: extension,
    });
    this.form.get('voucher')?.setValue(base64.split(',')[1]);
    this.uploaded = true;
  }
}
