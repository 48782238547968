import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-select-context',
  templateUrl: './select-context.component.html',
  styleUrls: ['./select-context.component.scss'],
})
export class SelectContextComponent implements OnInit {
  successful: boolean = false;
  roleName: string = '';
  roles: any[] = [];
  constructor(
    private router: Router,
    private modalRef: BsModalRef,
    private authSvc: AuthService,
    private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit(): void {}

  close() {
    this.modalRef.hide();
  }

  selectRole(id: string) {
    this.authSvc.selectContext(id).subscribe((data) => {
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('token', data.access_token);
      let rol = this.roles.find((r) => r._id == id);
      localStorage.setItem('role', rol.type);
      this.permissionsService.loadPermissions([rol.type]);
      this.router.navigate(['/']);
      this.modalRef.hide();
    });
  }
}
